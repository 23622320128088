html,
body {
  height: 100%;
}

body {
  background: #fafafa;
  place-items: flex-start;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: 400;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #3c3c3c !important;
}

* {
  -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(103, 155, 210, 0.03);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #407adb;
}

a {
  color: #407adb;
  text-decoration: none;
}

a:hover {
  color: #2e364c;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: 1rem;
}

.text-mute {
  color: #8b8b8b;
}

.mt-0 {
  margin-top: 0;
}

.ml-5 {
  margin-left: 0.5rem;
}

.mr-5 {
  margin-right: 0.5rem;
}

.mr-10 {
  margin-right: 1rem;
}

label {
  font-weight: 500;
  display: flex;
  margin-bottom: 3px;
  font-size: 0.75rem;
}

.rowBlk {
  display: flex;
  margin: 0 -10px;
}

.colBlk6 {
  flex: 0 0 50%;
  width: 50%;
  padding: 0 10px;
}

[class^=material-symbols-].smaller {
  font-size: 1.6rem;
}

.color-blue {
  color: #407adb;
}

.pageContainer {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.mainContent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 76px;
  background: #f5f5f5;
}

.contentWrap {
  flex: 1;
  padding: 10px 30px 30px;
}

.siteHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fbfbfb;
  padding: 10px 16px;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.siteLogo img {
  display: block;
  height: 50px;
  width: 50px;
}

.dropdown {
  color: #000;
  font-weight: 500;
}

.tabsMenu {
  display: flex;
}

.tabsMenu .tab-item {
  margin-right: 2rem;
  padding: 0.5rem 0;
  position: relative;
  color: #407adb;
  font-weight: 500;
}

.tabsMenu .tab-item::after {
  content: "";
  height: 4px;
  border-radius: 10px;
  background: #407adb;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  transition: all 0.4s;
}

.tabsMenu .tab-item.active {
  color: #2e364c;
}

.tabsMenu .tab-item.active::after,
.tabsMenu .tab-item:hover::after {
  width: 100%;
}

.tabsMenu .tab-item:last-child {
  margin-right: 0;
}

.uploadWrapper {
  padding: 16px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.uploadWrapper p {
  color: #000;
  margin-top: 0;
  margin-bottom: 1rem;
}

.uploadWrapper p:last-child {
  margin-bottom: 0;
}

.uploadWrapper .upload-hint {
  color: #666;
}

.uploadWrapper .material-symbols-outlined {
  font-size: 6rem;
  color: #407adb;
}

table tbody.ant-table-tbody tr td,
table thead.ant-table-thead tr th {
  padding: 5px 10px;
}

table thead.ant-table-thead tr th.custom-tbl-col::before {
  display: none;
}

.table .custom-table thead.ant-table-thead tr th.custom-tbl-col {
  background: none;
}

.table .custom-table tbody.ant-table-tbody tr td.custom-tbl-col.ant-table-cell-row-hover {
  background: none;
}

.table .custom-table .filesBox {
  background: #f2f2f2;
  border-radius: 0.4rem;
  padding: 0.5rem;
}

thead.ant-table-thead tr th.hiddenTblHeaderText {
  font-size: 0;
}

tbody.ant-table-tbody tr td a {
  color: #000;
}

.actions a {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
}

.progress-primary {
  margin-bottom: 0;
}

.progress-primary .ant-progress-bg {
  background-color: #7a5af8;
}

.deleteBtn {
  border: 0;
  padding: 0;
  line-height: 0.5;
  color: #000;
}

.deleteBtn:focus-visible {
  outline: 0 !important;
}

.actionIconWrap .anticon:hover {
  color: #407adb;
}

.actionIconWrap .deleteBtn:hover {
  color: #830311;
}

.ant-btn.ant-btn-default.btn-primary:not(:disabled) {
  border-color: #2e364c;
  background: #2e364c;
  color: #fff;
}

.ant-btn.ant-btn-default.btn-primary:not(:disabled):hover {
  color: #fff;
  background: #407adb;
  border-color: #407adb;
}

.ant-btn.ant-btn-default.btn-primary:focus-within {
  outline-color: #2e364c;
}

.ant-checkbox .ant-checkbox-inner {
  background-color: #fff;
  border-color: #2e364c;
}

.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
.ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #2e364c !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
  border-color: #2e364c;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  width: 6px;
  border-width: 2px;
  border-color: #2e364c;
}

.filter {
  margin-top: 1.6rem;
}

.tagRounded {
  border: 0;
  border-radius: 2rem;
  margin: 2px;
}

.ant-tag-blue {
  color: #2e364c;
  background: #e6eefa;
}

.ant-tag-blue .ant-tag-close-icon {
  color: #7a5af8;
}

.ant-tag-purple {
  color: #407adb;
  background: #deeaff;
}

.ant-tag-purple .ant-tag-close-icon {
  color: #407adb;
}

.tag-white {
  background: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.customTableStyle {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #dddada;
  overflow: hidden;
}

.customTableStyle .ant-table-wrapper .ant-table-pagination.ant-pagination {
  padding: 10px 12px;
  margin: 0;
}

.odd {
  background-color: #ffffff;
}

.even {
  background-color: #fbfbfb;
}

/*# sourceMappingURL=custom-style.css.map */

.footer {
  background-color: #fff;
  text-align: center;
  padding: 12px;
  
}

.footer a {
  color: inherit;
}

.loginWrapper {
  background: var(--wia-light-02);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loginWrapper h1 {
  margin-bottom: 16px;
}

.loginWrapper img {
  display: block;
  max-width: 240px;
}

.loginBlk {
  background-color: #fff;
  padding: 24px;
  width: 360px;
  max-width: 94%;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  font-size: 1rem;
}

h2 {
  font-size: 1.25rem;
  margin-bottom: 12px;
  color: #2e364c;
}

.loginBlk h2 {
  text-align: center;
}

.fldWrap {
  margin-bottom: 16px;
}

.fldWrap 

.alignRight {
  text-align: right !important;
}

.ant-btn {
  box-shadow: none;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 5px;
}

.fullWidth {
  width: 100%;
}

.ant-form-item .ant-form-item-label >label {
  font-size: 0.75rem;
  line-height: 1.3;
}

.forgotWrap {
  font-size: 0.75rem;
  text-align: right;
  margin: -10px 0 16px;
}

.forgotWrap div {
  opacity: 0.5;
}

.userDropdown {
  font-size: 0.875rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 8px 12px;
  vertical-align: top;
}

.ant-checkbox-group {
  padding: 5px 0;
}

.ant-select-dropdown .ant-select-item-option {
  margin: 1px 0;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: inherit;
  font-weight: 400;
  background-color: #e6eefa;
}

.btnWrap {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0;
}

.btnWrap > *:not(:first-child) {
  margin-left: 10px;
}

.upload-drag-icon svg {
  width: 60px;
  height: 60px;
  color: #407adb;
}

.dateBox {
  border-radius: 5px;
  padding: 5px;
  height: 32px;
  border: 1px solid #949494;
}

.fileNameBlk {
  display: inline-flex;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
}

.fileNameBlk .anticon {
  margin-right: 5px;
}

.fileName {
  cursor: pointer;
  color: #407adb;
}

.actionIconWrap > span {
  margin: 0 5px;
}

.detailPageWrap {
  display: flex;
}

.detailPageWrap .detailLeft {
  flex-wrap: 0 0 60%;
  width: 60%;
  padding-right: 30px;
}

.detailPageWrap .detailRight {
  flex: 1;
}

.detailRight iframe {
  width: 100%;
  height: calc(100vh - 230px);
  border: 1px solid #949494;
  border-radius: 10px;
}

.customTbl {
  border: 1px solid #dddada;
  border-radius: 5px;
}

.ant-modal-root .ant-modal-mask {
  background: rgba(0, 0, 0, 0.2);
}

.ant-modal {
  top: 50%;
  margin-top: -50px;
}

/* .container-style {
  display: flex;
  justify-content : space-between;
  align-items: center;
  width: 100%;
} */
.container-style {
  max-width: 100%;
  overflow-x: hidden;
}

.card-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.card {
 
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  
}
.recharts-legend-wrapper
{
  
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.over-time-chart-size {
  width: calc(33% - 20px);
  margin-bottom: 20px;
}

.ratio-chart-size {
  width: calc(50% - 20px);
  margin-bottom: 20px;
}

.growth-chart-size {
  width: calc(33% - 20px);
  margin-bottom: 20px;
}

